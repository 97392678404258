import { createMuiTheme } from "@material-ui/core"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000",
    },
    type: "light",
  },
  typography: {
    fontSize: 16,
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    h1: {
      fontFamily: ["Bebas Neue", "sans-serif"].join(","),
      fontWeight: 400,
    },
    h2: {
      fontFamily: ["Bebas Neue", "sans-serif"].join(","),
      fontWeight: 400,
    },
    h3: {
      fontFamily: ["Bebas Neue", "sans-serif"].join(","),
      fontWeight: 400,
    },
    h4: {
      fontFamily: ["Bebas Neue", "sans-serif"].join(","),
      fontWeight: 400,
    },
    h5: {
      fontFamily: ["Bebas Neue", "sans-serif"].join(","),
      fontWeight: 400,
    },
    body1: {
      marginBottom: "0.5rem",
      lineHeight: "1.6",
    },
    body2: {
      lineHeight: "1.5",
    },
    a: {
      color: "#000",
    },
  },
})

export default theme
